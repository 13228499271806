import React from "react";
import Layout from "../other/layout";
import SEO from "../other/seo";
import { useIntl, Link } from "gatsby-plugin-react-intl";
import Img from "gatsby-image";
import FadeIn from 'react-fade-in';
import { graphql, useStaticQuery } from "gatsby";
const TechnologyServices = () => {
  const intl = useIntl();
  const data = useStaticQuery(graphql`
    query {
      technologyServices: file(relativePath: { eq: "technologyServices.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }      
    }
  `);
  return (
    <Layout>
      <FadeIn>
        <SEO title={intl.formatMessage({ id: "innerpage-199" })} />
        <div>
          <FadeIn delay={200} transitionDuration={700}>
            <div className="mainlyt">
              <Img fluid={data.technologyServices.childImageSharp.fluid}
                className="img-responsive"
              />
            </div>
          </FadeIn>
          <div>
            <div className="bodyTextCss">
              <FadeIn className="BodyContainer bodytext" delay={210} transitionDuration={500}>

                <div className="">
                  <div className="assessment">{intl.formatMessage({ id: "innerpage-199" })}</div>
                </div>

                <h6>{intl.formatMessage({ id: "innerpage-200" })}</h6>
                <ul>
                    <li>{intl.formatMessage({ id: "innerpage-201" })}</li>
                    <li>{intl.formatMessage({ id: "innerpage-202" })}</li>
                    <li>{intl.formatMessage({ id: "innerpage-203" })}</li>
                    <li>{intl.formatMessage({ id: "innerpage-204" })}</li>
                    <li>{intl.formatMessage({ id: "innerpage-205" })}</li>
                </ul>
                <h6>{intl.formatMessage({ id: "innerpage-206" })}</h6>
                <p>{intl.formatMessage({ id: "innerpage-207" })}</p>
                <ul>
                    <li>{intl.formatMessage({ id: "innerpage-208" })}</li>

                    <li>{intl.formatMessage({ id: "innerpage-209" })}</li>

                    <li>{intl.formatMessage({ id: "innerpage-210" })}</li>

                    <li>{intl.formatMessage({ id: "innerpage-211" })}</li>

                    <li>{intl.formatMessage({ id: "innerpage-212" })}</li>

                    <li>{intl.formatMessage({ id: "innerpage-213" })}</li>

                    <li>{intl.formatMessage({ id: "innerpage-214" })}</li>

                    <li>{intl.formatMessage({ id: "innerpage-215" })}</li>

                    <li>{intl.formatMessage({ id: "innerpage-216" })}</li>

                    <li>{intl.formatMessage({ id: "innerpage-217" })}</li>

                    <li>{intl.formatMessage({ id: "innerpage-218" })}</li>

                    <li>{intl.formatMessage({ id: "innerpage-219" })}</li>
                </ul>
              </FadeIn>
            </div>

          </div>
        </div>
      </FadeIn>
    </Layout>

  );
};

export default TechnologyServices;
